import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"

import templateStyles from "./blog-list-template.module.css"

export default class BlogList extends React.Component {
  render() {
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    const posts = this.props.data.allMarkdownRemark.edges
    return (
      <Layout>
        <SEO title={`blog | ${currentPage}`} />
        {posts.map(({ node }) => {
          return <Post key={node.frontmatter.slug} node={node} summaryView={true} />
        })}
        <p className={templateStyles.navigation}>
          {!isFirst && (
            <Link to={`/blog/${prevPage}`} rel="prev">Newer posts</Link>
          )}
          {!isLast && (
            <Link to={`/blog/${nextPage}`} rel="next">Older posts</Link>
          )}
        </p>
      </Layout>
    )
  }
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {templateKey: {eq: null}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            date
            title
            slug
            tags
          }
        }
      }
    }
  }
`